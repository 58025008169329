@import url("https://fonts.googleapis.com/css2?family=Quicksand");

:root {
  --react-international-phone-height: 22px;
}
/* Adapted from DobiQueen brand colours */
$theme-colors: (
  "primary": #ffec01,
  "secondary": #f9f9f9,
  "success": #2a942e,
  "danger": #f66800,
  "warning": #f66800,
  "info": #df0169,
  // info repurposed as primary CTA
  "light": #f6f6f6,
  "dark": #242424,
);

// $link-color:            $primary !default;
// $link-decoration:       underline !default;
// $link-shade-percentage: 20% !default;
// $link-hover-color:      shift-color($link-color, $link-shade-percentage) !default;
// $link-hover-decoration: null !default;

// generic navbar

.navbar-text {
  font-weight: bold;
  @extend .text-dark;
}

$spacer: 1rem;

// checkout summary table style

table.dq-table-checkout-summary {
  td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// extra BGs

.bg-light-blue {
  background-color: #cdfffd80 !important;
}

.bg-light-red {
  background-color: #df01690d !important;
}

.bg-light-yellow {
  background-color: #fffde2 !important;
}

// style alert dialogues
.alert-info {
  background-color: #cdfffd80 !important;
  color: #242424 !important;
  border: none !important;
  font-size: 0.9em;
}

// style radio buttons
.form-check-input[type="radio"] {
  background-color: #f6f6f6;
  border-color: #df0169;
}

.form-check-input[type="radio"]:checked {
  background-color: #df0169;
  border-color: #df0169;
}

// horizontal rule

$hr-border-width: 1px !default;
$hr-opacity: 1 !default;

hr {
  border-width: 2px;
  .border-light {
    border-color: #d1d1d1 !important;
  }

  .border-dark {
    border-color: #242424 !important;
  }
}

// border custom colours
.border-light {
  border-color: #d1d1d1 !important;
}

.border-dark {
  border-color: #242424 !important;
}

.border-standard-light {
  border-color: #d1d1d1 !important;
  border-width: 1px;
}

// global utilities
.text-decoration-none {
  text-decoration: none !important;
}

/* import bootstrap to set changes */
@import "node_modules/bootstrap/scss/bootstrap";

.horizontal-scrolling {
  @extend .d-flex;
  @extend .flex-row;
  @extend .flex-nowrap;
  @extend .position-relative;
  @extend .overflow-auto;
}
@include media-breakpoint-up(md) {
  .horizontal-scrolling {
    // display: block !important;
    // position: auto !important;
    // overflow: auto !important;
    // flex: none !important;
    flex-wrap: wrap !important;
  }
}

body {
  font-family: "Quicksand", Arial, Helvetica, sans-serif;
}

.react-datepicker-wrapper {
  width: 100%;
}

.w-select {
  border: 1px solid #cccccc !important;
}

.is-required {
  color: #df0169;
}

.x-stack-picker-item.disabled,
.x-stack-picker-item.disabled a.x-stack-picker-btn {
  cursor: default;
  color: #a4a4a4;
}
.x-stack-picker-item.disabled:hover {
  border-color: #d1d1d1;
}

.x-actionbar-block {
  justify-content: space-between;
}

.w-input.disabled,
.react-international-phone-input--disabled {
  background-color: #eeeeee;
}

input,
textarea {
  -webkit-user-select: text !important; /* Chrome, Opera, Safari */
  -moz-user-select: text !important; /* Firefox 2+ */
  -ms-user-select: text !important; /* IE 10+ */
  user-select: text !important; /* Standard syntax */
}

button.whatsapp:hover {
  opacity: 0.8;
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s;
}
